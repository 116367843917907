import {
  Box,
  Flex,
  Text,
  Button,
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Divider,
  Icon,
  UnorderedList,
  ListItem,
  Image,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Island from "../components/Island";

import { ReactComponent as ScheduleIcon } from "../icons/schedule.svg";
import { ReactComponent as CheckCirlceIcon } from "../icons/check_circle.svg";
import Articles from "../components/Articles";
import RoadMapItem from "../components/RoadMapItem";
import { toast } from "react-toastify";
import ReactPlayer from "react-player/youtube";

import { GoCheck } from "react-icons/go";
import { BsArrowRight, BsClock, BsHourglassSplit } from "react-icons/bs";
import { IoClose } from "react-icons/io5";
import { HiOutlineArrowUp } from "react-icons/hi";
import { useTranslation } from "react-i18next";

const About = () => {
  const { t } = useTranslation();

  const location = useLocation();
  const navigate = useNavigate();

  const introRef = useRef<HTMLDivElement>(null);
  const basicInfoRef = useRef<HTMLDivElement>(null);
  const reviewsRef = useRef<HTMLDivElement>(null);
  const roadmapRef = useRef<HTMLDivElement>(null);
  const portfolioRef = useRef<HTMLDivElement>(null);
  const howItWorksRef = useRef<HTMLDivElement>(null);
  const pricingRef = useRef<HTMLDivElement>(null);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [data, setData] = useState<any>(undefined);

  useEffect(() => {
    (async () => {
      try {
        const res = await fetch("https://api.botcalculator.com/banner_stats");
        const json = await res.json();
        setData(json.Data);
      } catch {
        toast("Nepodařilo se načíst data", { type: "error" });
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  useEffect(
    () => {
      let y = 0;

      switch (location.hash) {
        case "#introduction":
          y = introRef.current?.offsetTop ?? 0;
          break;
        case "#basic_information":
          y = basicInfoRef.current?.offsetTop ?? 0;
          break;
        case "#reviews":
          y = reviewsRef.current?.offsetTop ?? 0;
          break;
        case "#roadmap":
          y = roadmapRef.current?.offsetTop ?? 0;
          break;
        case "#portfolio":
          y = portfolioRef.current?.offsetTop ?? 0;
          break;
        case "#more_info":
          y = howItWorksRef.current?.offsetTop ?? 0;
          break;
        case "#pricing":
          y = pricingRef.current?.offsetTop ?? 0;
          break;
        default:
          break;
      }
      setTimeout(() => {
        window.scrollTo(0, y - 80);
      }, 10);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location.hash]
  );

  return (
    <>
      <div ref={introRef}>
        <Island id="#introduction" backgroundImage="about_first.png">
          <Text fontSize="3xl" fontWeight="bold" mb={8}>
            {t("introduction:section_1:title")}
          </Text>

          <Flex gap={4}>
            <Button
              bg="brand"
              color="island"
              fontSize={16}
              fontWeight={400}
              onClick={() => navigate("/signup")}
            >
              {t("introduction:section_1:button")}
              <Icon ml={2} as={BsArrowRight} />
            </Button>

            <a
              href="https://calendly.com/hodlbot/30min"
              target="_blank"
              rel="noreferrer"
            >
              <Button bg="white" color="island" fontSize={16} fontWeight={400}>
                {t("online_meeting:title")}
                <Icon ml={2} as={BsArrowRight} />
              </Button>
            </a>
          </Flex>
        </Island>
      </div>

      <Island
        bg="#8DE9FF"
        p={0}
        flexDir="row"
        justifyContent="space-between"
        display={{ base: "none", sm: "flex" }}
      >
        <Flex w={{ base: "full", lg: "50%" }} flexDir="column" h="full">
          <Flex
            justifyContent="space-evenly"
            flexDir={{ base: "column", lg: "row" }}
            h="full"
            alignItems="center"
          >
            <Flex
              bg="#0041AC"
              flexDir="column"
              p={6}
              borderRadius="3xl"
              justifyContent="center"
              m={4}
              h="fit-content"
            >
              <Text color="#50C8FC" fontSize={36}>
                {isLoading ? "..." : data?.Transactions}
              </Text>
              <Text color="#F7F7FC">{t("introduction:section_2:box_1")}</Text>
            </Flex>
            <Flex
              bg="#0041AC"
              flexDir="column"
              p={6}
              borderRadius="3xl"
              justifyContent="center"
              m={4}
              h="fit-content"
            >
              <Text color="#50C8FC" fontSize={36}>
                {isLoading ? "..." : data?.Profit.slice(0, 7)} BTC
              </Text>
              <Text color="#F7F7FC">{t("introduction:section_2:box_2")}</Text>
            </Flex>
          </Flex>
        </Flex>
        <Flex
          display={{ base: "none", lg: "flex" }}
          position="relative"
          h="full"
          alignItems="flex-end"
        >
          <img src="earned.png" />
          <Text position="absolute" bottom={0} color="island" ml={6} right={2}>
            {t("introduction:section_2:from")} 1.1.2023
          </Text>
        </Flex>
      </Island>

      <div ref={basicInfoRef}>
        <Island id="#basic_information">
          <Flex flexDir="column" mb={4}>
            <Text fontSize={36} mb={4}>
              {t("introduction:section_3:title")}
            </Text>
            <Text my={4}>
              {t("introduction:section_3:text_1:part_1")}
              <b style={{ color: "#50C8FC" }}>
                {" "}
                {t("introduction:section_3:text_1:part_2")}
              </b>{" "}
              {t("introduction:section_3:text_1:part_3")}{" "}
              <b style={{ color: "#50C8FC" }}>
                {" "}
                {t("introduction:section_3:text_1:part_4")}
              </b>{" "}
              {t("introduction:section_3:text_1:part_5")}{" "}
              <b style={{ color: "#50C8FC" }}>
                {" "}
                {t("introduction:section_3:text_1:part_6")}
              </b>
            </Text>
          </Flex>

          <Flex
            flexDir={{ base: "column", lg: "row" }}
            bg="bg"
            borderRadius="lg"
            alignItems="center"
          >
            <Image src="test1.png" w={256} h={256} borderRadius={10} m={4} />

            <Flex flexDir="column">
              <Text mx={8} mt={8} fontSize="2xl">
                {t("introduction:section_3:title_2")}
              </Text>
              <Text m={8}>
                {t("introduction:section_3:text_2:part_1")}{" "}
                <b style={{ color: "#50C8FC" }}>
                  {t("introduction:section_3:text_2:part_2")}
                </b>{" "}
                {t("introduction:section_3:text_2:part_3")}
                <b style={{ color: "#50C8FC" }}>
                  {" "}
                  {t("introduction:section_3:text_2:part_4")}
                </b>{" "}
                <b style={{ color: "#50C8FC" }}>
                  {" "}
                  {t("introduction:section_3:text_2:part_5")}
                </b>{" "}
                {t("introduction:section_3:text_2:part_6")}{" "}
                <b style={{ color: "#50C8FC" }}>
                  {t("introduction:section_3:text_2:part_7")}
                </b>
                {t("introduction:section_3:text_2:part_8")}
              </Text>
              <Flex justifyContent="center">
                <Button
                  bg="brand"
                  color="island"
                  fontSize={16}
                  fontWeight={400}
                  onClick={() => navigate("/#more_info")}
                  w="fit-content"
                  mb={8}
                >
                  {t("introduction:section_3:button")}
                  <Icon ml={2} as={BsArrowRight} />
                </Button>
              </Flex>
            </Flex>
          </Flex>

          <Flex
            flexDir={{ base: "column", lg: "row" }}
            bg="bg"
            my={8}
            borderRadius="lg"
            alignItems="center"
          >
            <Flex flexDir="column">
              <Text mx={8} mt={8} fontSize="2xl">
                {t("introduction:section_3:title_3")}
              </Text>
              <Text m={8}>
                {t("introduction:section_3:text_3:part_1")}{" "}
                <b style={{ color: "#50C8FC" }}>
                  {" "}
                  {t("introduction:section_3:text_3:part_2")}{" "}
                </b>{" "}
                {t("introduction:section_3:text_3:part_3")}{" "}
                <b style={{ color: "#50C8FC" }}>
                  {" "}
                  {t("introduction:section_3:text_3:part_4")}{" "}
                </b>
                {t("introduction:section_3:text_3:part_5")} <br />
                <br />
                {t("introduction:section_3:text_3:part_6")}{" "}
                <b style={{ color: "#50C8FC" }}>
                  {" "}
                  {t("introduction:section_3:text_3:part_7")}
                </b>{" "}
                {t("introduction:section_3:text_3:part_8")}{" "}
                <b style={{ color: "#50C8FC" }}>
                  {" "}
                  {t("introduction:section_3:text_3:part_9")}{" "}
                </b>{" "}
                {t("introduction:section_3:text_3:part_10")}{" "}
                <b style={{ color: "#50C8FC" }}>
                  {" "}
                  {t("introduction:section_3:text_3:part_11")}
                </b>
                {t("introduction:section_3:text_3:part_12")}
              </Text>
            </Flex>
            <Image src="test2.png" w={256} h={256} borderRadius={10} m={4} />
          </Flex>

          <Flex flexDir={{ base: "column", lg: "row" }} w="full" gap={6}>
            <Flex
              w={{ base: "auto", lg: "50%" }}
              borderRadius="lg"
              overflow="hidden"
            >
              <ReactPlayer
                url="https://www.youtube.com/watch?v=GfGdf3bCxg0&ab_channel=Tom%C3%A1%C5%A1Velek"
                width={"100%"}
              />
            </Flex>
            <Flex
              flexDir={{ base: "column", lg: "row" }}
              bg="bg"
              borderRadius="lg"
              w={{ base: "auto", lg: "50%" }}
              pb={8}
            >
              <Flex flexDir="column">
                <Text m={8} fontSize="2xl">
                  {t("introduction:section_3:title_4")}
                </Text>
                <Flex flexDir="column" gap={2}>
                  <Text
                    mx={8}
                    lineHeight="lg"
                    gap={2}
                    alignItems="center"
                    display="flex"
                  >
                    <Icon as={GoCheck} fontSize="2xl" />
                    <b style={{ color: "#50C8FC" }}>
                      {" "}
                      {t("introduction:section_3:text_4:part_1")}{" "}
                    </b>
                    {t("introduction:section_3:text_4:part_2")}{" "}
                  </Text>
                  <Text
                    alignItems="center"
                    display="flex"
                    mx={8}
                    lineHeight="lg"
                    gap={2}
                  >
                    <Icon as={IoClose} fontSize="2xl" />
                    <b style={{ color: "#50C8FC" }}>
                      {t("introduction:section_3:text_4:part_3")},
                    </b>
                    {t("introduction:section_3:text_4:part_4")}{" "}
                  </Text>
                  <Text
                    alignItems="center"
                    display="flex"
                    mx={8}
                    lineHeight="lg"
                    gap={2}
                  >
                    <Icon as={HiOutlineArrowUp} fontSize="2xl" />
                    {t("introduction:section_3:text_4:part_5")}{" "}
                  </Text>
                  <Text
                    alignItems="center"
                    display="flex"
                    mx={8}
                    lineHeight="lg"
                    gap={2}
                  >
                    <Icon as={GoCheck} fontSize="2xl" />
                    {t("introduction:section_3:text_4:part_6")}{" "}
                  </Text>

                  <Text
                    alignItems="center"
                    display="flex"
                    mx={8}
                    lineHeight="lg"
                    gap={2}
                  >
                    <Icon as={BsHourglassSplit} fontSize="2xl" />
                    {t("introduction:section_3:text_4:part_7")}{" "}
                    <b style={{ color: "#50C8FC" }}>
                      {t("introduction:section_3:text_4:part_8")}{" "}
                    </b>
                  </Text>
                  <Text
                    alignItems="center"
                    display="flex"
                    mx={8}
                    lineHeight="lg"
                    gap={2}
                  >
                    <Icon as={BsClock} fontSize="2xl" />
                    {t("introduction:section_3:text_4:part_9")}{" "}
                    <b style={{ color: "#50C8FC" }}>
                      {" "}
                      {t("introduction:section_3:text_4:part_10")}{" "}
                    </b>
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Island>
      </div>

      <Island
        bg="#8DE9FF"
        p={0}
        flexDir="row"
        justifyContent="space-between"
        display={{ base: "flex", sm: "none" }}
      >
        <Flex w={{ base: "full", lg: "50%" }} flexDir="column" h="full">
          <Flex
            justifyContent="space-evenly"
            flexDir={{ base: "column", lg: "row" }}
            h="full"
            alignItems="center"
          >
            <Flex
              bg="#0041AC"
              flexDir="column"
              p={6}
              borderRadius="3xl"
              justifyContent="center"
              m={4}
              h="fit-content"
            >
              <Text color="#50C8FC" fontSize={36}>
                {isLoading ? "..." : data?.Transactions}
              </Text>
              <Text color="#F7F7FC">{t("introduction:section_2:box_1")} </Text>
            </Flex>
            <Flex
              bg="#0041AC"
              flexDir="column"
              p={6}
              borderRadius="3xl"
              justifyContent="center"
              m={4}
              h="fit-content"
            >
              <Text color="#50C8FC" fontSize={36}>
                {isLoading ? "..." : data?.Profit} BTC
              </Text>
              <Text color="#F7F7FC">{t("introduction:section_2:box_2")} </Text>
            </Flex>
          </Flex>
          <Text position="relative" bottom={0} color="island" ml={6}>
            {t("introduction:section_2:from")} 1.1.2023
          </Text>
        </Flex>
        <Flex display={{ base: "none", lg: "flex" }}>
          <img src="earned.png" />
        </Flex>
      </Island>

      <div ref={portfolioRef}>
        <Island>
          <Text fontSize={36} mb={8}>
            {t("introduction:section_4:title")}
          </Text>
          <Flex flexDir={{ base: "column", lg: "row" }} w="full" gap={6}>
            <Flex
              flexDir="column"
              w={{ base: "auto", lg: "50%" }}
              justifyContent="center"
            >
              <Flex
                flexDir="column"
                bg="bg"
                borderRadius="lg"
                p={8}
                h="100%"
                height="full"
              >
                <UnorderedList spacing={2}>
                  <ListItem>
                    {t("introduction:section_4:text_1:part_1")}{" "}
                    <b
                      style={{
                        color: "#50C8FC",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        window.open(
                          "https://discord.com/channels/944287083005112340/971808081195462696/971809182955208784"
                        )
                      }
                    >
                      {t("introduction:section_4:text_1:part_2")}{" "}
                    </b>
                  </ListItem>
                  <ListItem>
                    {t("introduction:section_4:text_1:part_3")}{" "}
                    <b style={{ color: "#50C8FC" }}>
                      {t("introduction:section_4:text_1:part_4")}{" "}
                    </b>
                  </ListItem>
                  <ListItem>
                    {t("introduction:section_4:text_1:part_5")}{" "}
                    <b style={{ color: "#50C8FC" }}>
                      {" "}
                      {t("introduction:section_4:text_1:part_6")}{" "}
                    </b>
                  </ListItem>
                  <ListItem>
                    {t("introduction:section_4:text_1:part_7")}{" "}
                  </ListItem>
                  <ListItem>
                    {t("introduction:section_4:text_1:part_8")}{" "}
                  </ListItem>
                  <ListItem>
                    {t("introduction:section_4:text_1:part_9")}{" "}
                    <b style={{ color: "#50C8FC" }}>
                      {" "}
                      {t("introduction:section_4:text_1:part_10")}{" "}
                    </b>{" "}
                    {t("introduction:section_4:text_1:part_11")}{" "}
                    <b style={{ color: "#50C8FC" }}>
                      {" "}
                      {t("introduction:section_4:text_1:part_12")}{" "}
                    </b>
                  </ListItem>
                  <ListItem>
                    <b style={{ color: "#50C8FC" }}>
                      {t("introduction:section_4:text_1:part_13")}{" "}
                    </b>
                  </ListItem>
                  <ListItem>
                    {t("introduction:section_4:text_1:part_14")}{" "}
                    <b style={{ color: "#50C8FC" }}>
                      {t("introduction:section_4:text_1:part_15")}{" "}
                    </b>{" "}
                    {t("introduction:section_4:text_1:part_16")}{" "}
                    <b style={{ color: "#50C8FC" }}>
                      {" "}
                      {t("introduction:section_4:text_1:part_17")}{" "}
                    </b>
                  </ListItem>
                  <ListItem>
                    {t("introduction:section_4:text_1:part_18")} <br />
                    <b style={{ color: "#50C8FC" }}>
                      {t("introduction:section_4:text_1:part_19")}{" "}
                    </b>
                    {t("introduction:section_4:text_1:part_20")}{" "}
                    <b style={{ color: "#50C8FC" }}>
                      {t("introduction:section_4:text_1:part_21")}{" "}
                    </b>
                  </ListItem>
                  <ListItem>
                    <b style={{ color: "#50C8FC" }}>
                      {" "}
                      {t("introduction:section_4:text_1:part_22")}{" "}
                    </b>
                    {t("introduction:section_4:text_1:part_23")}{" "}
                    <b style={{ color: "#50C8FC" }}>
                      {" "}
                      {t("introduction:section_4:text_1:part_24")}{" "}
                    </b>
                  </ListItem>
                  <ListItem>
                    <b style={{ color: "#50C8FC" }}>
                      {" "}
                      {t("introduction:section_4:text_1:part_25")}{" "}
                    </b>
                  </ListItem>

                  <ListItem>
                    {t("introduction:section_4:text_1:part_26")}{" "}
                    <b
                      style={{
                        color: "#50C8FC",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                      onClick={() => navigate("/statistics")}
                    >
                      {t("introduction:section_4:text_1:part_27")}{" "}
                    </b>
                  </ListItem>
                </UnorderedList>
              </Flex>
            </Flex>
            <Flex
              w={{ base: "auto", lg: "50%" }}
              borderRadius="lg"
              overflow="hidden"
              alignItems="center"
              flexDir="column"
              gap={4}
            >
              <Flex borderRadius="lg" overflow="hidden" w="full">
                <ReactPlayer
                  url="https://www.youtube.com/watch?v=6xl8iBU6szI&ab_channel=Tom%C3%A1%C5%A1Velek"
                  width={"100%"}
                />
              </Flex>

              <img src="stats_screenshot.png" />
            </Flex>
          </Flex>
        </Island>
      </div>

      <div ref={howItWorksRef}>
        <Island>
          <Text fontSize={36} mb={8}>
            {t("introduction:section_5:title")}{" "}
          </Text>
          <Flex flexDir={{ base: "column", lg: "row" }} w="full" gap={6}>
            <Flex
              w={{ base: "auto", lg: "50%" }}
              borderRadius="lg"
              overflow="hidden"
              alignItems="center"
              flexDir="column"
              gap={4}
              bg="bg"
              p={8}
            >
              <Text fontSize={24} mb={4}>
                {t("introduction:section_5:title_2")}{" "}
              </Text>
              <Text>
                <b style={{ color: "#50C8FC" }}>
                  {" "}
                  {t("introduction:section_5:text_1:part_1")}{" "}
                </b>
                {t("introduction:section_5:text_1:part_2")} <br /> <br />{" "}
                <b style={{ color: "#50C8FC" }}>
                  {t("introduction:section_5:text_1:part_3")}{" "}
                </b>
                {t("introduction:section_5:text_1:part_4")} <br /> <br />
                <b style={{ color: "#50C8FC" }}>
                  {" "}
                  {t("introduction:section_5:text_1:part_5")}{" "}
                </b>
                {t("introduction:section_5:text_1:part_6")} <br /> <br />
                {t("introduction:section_5:text_1:part_7")}{" "}
                <b style={{ color: "#50C8FC" }}>
                  {t("introduction:section_5:text_1:part_8")}{" "}
                </b>{" "}
                {t("introduction:section_5:text_1:part_9")}{" "}
                <b style={{ color: "#50C8FC" }}>
                  {" "}
                  {t("introduction:section_5:text_1:part_10")}{" "}
                </b>
                {t("introduction:section_5:text_1:part_11")}{" "}
                <b style={{ color: "#50C8FC" }}>
                  {t("introduction:section_5:text_1:part_12")}{" "}
                </b>
                <br /> <br />
                {t("introduction:section_5:text_1:part_13")}{" "}
                <b style={{ color: "#50C8FC" }}>
                  {" "}
                  {t("introduction:section_5:text_1:part_14")}{" "}
                </b>{" "}
                {t("introduction:section_5:text_1:part_15")} <br /> <br />
                {t("introduction:section_5:text_1:part_16")}{" "}
                <b style={{ color: "#50C8FC" }}>
                  {" "}
                  {t("introduction:section_5:text_1:part_17")}{" "}
                </b>
                {t("introduction:section_5:text_1:part_18")} <br /> <br />
                <b style={{ color: "#50C8FC" }}>
                  {t("introduction:section_5:text_1:part_19")}{" "}
                </b>
              </Text>
            </Flex>
            <Flex
              w={{ base: "auto", lg: "50%" }}
              borderRadius="lg"
              overflow="hidden"
              alignItems="center"
              flexDir="column"
              gap={4}
              bg="bg"
              p={8}
            >
              <Text fontSize={24} mb={4}>
                {t("introduction:section_5:title_3")}{" "}
              </Text>
              <Text>
                {t("introduction:section_5:text_2:part_1")}{" "}
                <b style={{ color: "#50C8FC" }}>
                  {t("introduction:section_5:text_2:part_2")}{" "}
                </b>
                {t("introduction:section_5:text_2:part_3")} .
                <br /> <br />
                <b style={{ color: "#50C8FC" }}>
                  {t("introduction:section_5:text_2:part_4")}{" "}
                </b>
                {t("introduction:section_5:text_2:part_5")}{" "}
                <b style={{ color: "#50C8FC" }}>
                  {" "}
                  {t("introduction:section_5:text_2:part_6")}{" "}
                </b>{" "}
                {t("introduction:section_5:text_2:part_7")}{" "}
                <b style={{ color: "#50C8FC" }}>
                  {t("introduction:section_5:text_2:part_8")}{" "}
                </b>
                {t("introduction:section_5:text_2:part_9")} <br /> <br />{" "}
                {t("introduction:section_5:text_2:part_10")}{" "}
                <b style={{ color: "#50C8FC" }}>
                  {" "}
                  {t("introduction:section_5:text_2:part_11")}{" "}
                </b>{" "}
                {t("introduction:section_5:text_2:part_12")}{" "}
                <b style={{ color: "#50C8FC" }}>
                  {t("introduction:section_5:text_2:part_13")}{" "}
                </b>
                <br /> <br />
                {t("introduction:section_5:text_2:part_17")}{" "}
              </Text>
              <ReactPlayer
                url="https://www.youtube.com/watch?v=-Yk7t2rrkfo&ab_channel=Tom%C3%A1%C5%A1Velek"
                width={"100%"}
              />
            </Flex>
          </Flex>
        </Island>
      </div>

      <div ref={pricingRef}>
        <Island>
          <Text fontSize="3xl" fontWeight="bold" mb={8}>
            {t("menu:pricing")}
          </Text>

          <Text>
            {t("pricing:text_1:part_1")}
            <b style={{ color: "#50C8FC" }}> {t("pricing:text_1:part_2")}</b>
            {t("pricing:text_1:part_3")}
            <b style={{ color: "#50C8FC" }}> {t("pricing:text_1:part_4")}</b>
            {t("pricing:text_1:part_5")}
            <b style={{ color: "#50C8FC" }}> {t("pricing:text_1:part_6")}</b>
          </Text>
          <br />
          <Text>{t("pricing:text_2")}</Text>
          <br />

          <Text>{t("pricing:text_3")}</Text>
          <br />

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "20px",
            }}
          >
            <img src="pricing.png" style={{ width: "50%" }} />
            <Text width="full" textAlign="center">
              {t("pricing:text_4:part_1")}{" "}
              <b style={{ color: "#50C8FC" }}>{t("pricing:text_4:part_2")}</b>
              <br />
              <br />
              {t("pricing:text_4:part_3")}
              <br />
              0,012 - 0,06 = <b style={{ color: "#008000	" }}> 0,06</b>
              <br />
              <b style={{ color: "#008000	" }}> {t("pricing:text_4:part_6")}</b>
              <br />
              <br />
              <b style={{ color: "#008000	" }}>0,06</b> * 15% ={" "}
              <b style={{ color: "#FFA500" }}> 0,009</b>
              <br />
              <b style={{ color: "#FFA500" }}> {t("pricing:text_4:part_9")}</b>
              <br />
              <br />
              <b style={{ color: "#FFA500" }}>
                {t("pricing:text_4:part_10")}
              </b>{" "}
              <b style={{ color: "#50C8FC" }}>* 100 000</b> ={" "}
              <b style={{ color: "white" }}> 900 CZK </b>
              <br />
              <b style={{ color: "#ffffff" }}>{t("pricing:text_4:part_13")} </b>
              <br />
              <br />
              <b style={{ color: "#008000	" }}>0,06</b>{" "}
              <b style={{ color: "#50C8FC" }}>* 100 000</b> ={" "}
              <b style={{ color: "white" }}> 6000 CZK </b>
              <br />
              <b style={{ color: "#ffffff" }}>{t("pricing:text_4:part_14")}</b>
              <br />
              <br />
              <b style={{ color: "#ffffff" }}>6000 CZK - 900 CZK = 5100 CZK </b>
              <br />
              <b style={{ color: "#ffffff" }}>
                {" "}
                {t("pricing:text_4:part_15")}{" "}
              </b>
            </Text>
          </div>
        </Island>
      </div>

      <div ref={reviewsRef}>
        <Island>
          <Text fontSize={36} mb={8}>
            {t("introduction:section_6:title")}{" "}
          </Text>
          <Articles />
        </Island>
      </div>

      {/* <div ref={roadmapRef}>
        <Island id='#roadmap'>
          <Text fontSize={36} mb={8}>
            {t('introduction:section_7:title')}{' '}
          </Text>
          <Accordion w='full' allowMultiple bg='bg' borderRadius='lg'>
            <AccordionItem border='none'>
              <h2>
                <AccordionButton>
                  <Box borderRadius='lg' bg='#008A00' p={2} mr={4} my={2}>
                    <CheckCirlceIcon fill='#FFFFFF' />
                  </Box>
                  <Box as='span' flex='1' textAlign='left'>
                    {t('introduction:section_7:title_2')}{' '}
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <Divider mb={4} />
                <RoadMapItem
                  date='Q2 2021'
                  text={t('introduction:section_7:text:part_2')}
                />
                <RoadMapItem
                  date='Q2 2021'
                  text={t('introduction:section_7:text:part_4')}
                />
                <RoadMapItem
                  date='Q3 2021'
                  text={t('introduction:section_7:text:part_6')}
                />
                <RoadMapItem
                  date='Q4 2021'
                  text={t('introduction:section_7:text:part_8')}
                />
                <RoadMapItem
                  date='Q1 2022'
                  text={t('introduction:section_7:text:part_10')}
                />
                <RoadMapItem
                  date='Q1 2022'
                  text={t('introduction:section_7:text:part_12')}
                />
                <RoadMapItem
                  date='Q2 2022'
                  text={t('introduction:section_7:text:part_14')}
                />
                <RoadMapItem
                  date='Q2 2022'
                  text={t('introduction:section_7:text:part_16')}
                />
                <RoadMapItem
                  date='Q3 2022'
                  text={t('introduction:section_7:text:part_18')}
                />

                <RoadMapItem
                  date='Q1 2023'
                  text={t('introduction:section_7:text:part_20')}
                />
                <RoadMapItem
                  date='Q2 2023'
                  text={t('introduction:section_7:text:part_22')}
                />
                <RoadMapItem
                  date='Q2 2023'
                  text={t('introduction:section_7:text_2:part_2')}
                />
                <RoadMapItem
                  date='Q2 2023'
                  text={t('introduction:section_7:text_2:part_4')}
                />
              </AccordionPanel>
            </AccordionItem>
          </Accordion>

          <Accordion
            w='full'
            defaultIndex={[0]}
            allowMultiple
            bg='bg'
            borderRadius='lg'
            m={8}
          >
            <AccordionItem border='none'>
              <h2>
                <AccordionButton>
                  <Box borderRadius='lg' bg='#EAAC30' p={2} mr={4} my={2}>
                    <ScheduleIcon fill='#FFFFFF' />
                  </Box>
                  <Box as='span' flex='1' textAlign='left'>
                    {t('introduction:section_7:title_3')}{' '}
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <Divider mb={4} />
                <RoadMapItem
                  date='Q2 2023'
                  text={t('introduction:section_7:text_3:part_2')}
                />
              </AccordionPanel>
            </AccordionItem>
          </Accordion>

          <Accordion w='full' allowMultiple bg='bg' borderRadius='lg'>
            <AccordionItem border='none'>
              <h2>
                <AccordionButton>
                  <Box borderRadius='lg' bg='#005BD4' p={2} my={2} mr={4}>
                    <ScheduleIcon fill='#FFFFFF' />
                  </Box>
                  <Box as='span' flex='1' textAlign='left'>
                    {t('introduction:section_7:title_4')}{' '}
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <Divider mb={4} />

                <RoadMapItem
                  date='Q2 2024'
                  text={t('introduction:section_7:text_3:part_8')}
                />
                <RoadMapItem
                  date='Q3 2024'
                  text={t('introduction:section_7:text_3:part_10')}
                />
                <RoadMapItem
                  date='Q3 2024'
                  text={t('introduction:section_7:text_3:part_12')}
                />
                <RoadMapItem
                  date='Q4 2024'
                  text={t('introduction:section_7:text_3:part_6')}
                />
                <RoadMapItem
                  date='Q4 2024'
                  text={t('introduction:section_7:text_3:part_4')}
                />
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </Island>
      </div> */}

      <Island>
        <Text fontSize={36} mb={8}>
          {t("introduction:section_8:title")}{" "}
        </Text>
        <Flex
          flex={1}
          gap={10}
          flexDir={{ base: "column", md: "row", lg: "row" }}
        >
          <Image
            src="binance-logo.png"
            style={{ objectFit: "contain", cursor: "pointer" }}
            maxW={{ base: "100%", md: "30%", lg: "30%" }}
            onClick={() =>
              window.open("https://accounts.binance.com/register?ref=BFDRW8BB")
            }
          />
          <Image
            src="crypto-friends-logo.png"
            style={{ objectFit: "contain", cursor: "pointer" }}
            maxW={{ base: "100%", md: "30%", lg: "30%" }}
            onClick={() => window.open("https://cryptofriends.cz/")}
          />
          <Image
            src="phemex-logo.png"
            style={{ objectFit: "contain", cursor: "pointer" }}
            maxW={{ base: "100%", md: "30%", lg: "30%" }}
            onClick={() => window.open("https://phemex.com/a/k/hodlbot")}
          />
        </Flex>
      </Island>
    </>
  );
};

export default About;
