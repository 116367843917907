import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Divider,
  Flex,
  Icon,
  Spinner,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";

import Island from "../components/Island";

import { useContext, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { GraphData, GraphRecord, TableRecord } from "../utils/types";
import { getUserGraphs, getUserTables } from "../api/graphs";
import { directus } from "../api/directus";
import { SessionCtx } from "../context/SessionContext";
import ParentSize from "@visx/responsive/lib/components/ParentSize";
import Graph from "../components/graph/Graph";
import { Tooltip } from "react-tooltip";
import { ReactComponent as CalculatorIcon } from "../icons/calculate.svg";

import img_1 from "../images/1.png";
import img_2 from "../images/2.png";
import Calculator from "./Calculator";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useTranslation } from "react-i18next";

const x100 = (string: string) => {
  const idx = string.indexOf(".");
  const removedDecimalPoint = string.split(".").join("");
  const final = parseFloat(
    removedDecimalPoint.slice(0, idx + 2) +
      "." +
      removedDecimalPoint.slice(idx + 2)
  ).toString();

  return final;
};

const dark = (number: number, higherThan: number) =>
  number !== 0 ? "#A6F787" : "#FF75CB";

const PortfolioDetail = () => {
  const { t } = useTranslation();

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 10);
  }, []);

  const location = useLocation();
  const navigate = useNavigate();

  const { user } = useContext(SessionCtx);

  const instanceId = location.pathname.split("/")[2];

  const [graphsData, setGraphsData] = useState<GraphRecord[]>([]);
  const [tableData, setTableData] = useState<TableRecord[]>([]);

  const [item, setItem] = useState<any>(undefined);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      if (tableData) {
        const res = await directus.items("aa_bot_instances").readByQuery();

        const itemRes = (res.data as any[])?.find(
          (item) => item.portfolio_id_str === instanceId
        );
        setItem(itemRes);
        setIsLoading(false);
      }
    })();
  }, [instanceId, tableData]);

  // Initial data fetch.
  useEffect(() => {
    getUserGraphs(directus, setGraphsData, user?.id ?? "");
    getUserTables(directus, setTableData, user?.id ?? "");
  }, []);

  const graphsDataTranformed: GraphData = useMemo(() => {
    let graphsRecordsTransformed: GraphData = {};

    if (!graphsData) return graphsRecordsTransformed;

    graphsData.map((graphRecord) => {
      graphsRecordsTransformed[graphRecord.InstanceId] = {
        data: [],
        flags: graphRecord.Flag,
      };
    });

    graphsData.map((graphRecord) => {
      graphsRecordsTransformed[graphRecord.InstanceId].data.push(graphRecord);
    });

    return graphsRecordsTransformed;
  }, [graphsData]);

  if (isLoading) {
    return (
      <>
        <Island>
          <Text align="center" fontSize="3xl" width="full" mb={10}>
            {t("portfolio:instance_detail:title")} {instanceId}
          </Text>
          <Flex justifyContent="center" alignItems="center" h="full">
            <Spinner />
          </Flex>
        </Island>
      </>
    );
  }

  if (!tableData.map((i) => i.InstanceId).includes(instanceId)) {
    return (
      <Island>
        <Text align="center" fontSize="3xl" width="full" mb={10}>
          {t("portfolio:instance_detail:title")} {instanceId}
        </Text>
        <Flex justifyContent="center" alignItems="center" h="full">
          {t("portfolio:instance_detail:no_rights")}
        </Flex>
      </Island>
    );
  }

  return (
    <>
      <Island>
        <Flex justifyContent="space-between" w="full">
          <Icon
            color="white"
            as={AiOutlineArrowLeft}
            fontSize="2xl"
            my={3}
            style={{
              cursor: "pointer",
            }}
            onClick={() => navigate(-1)}
          />
          <Text align="center" fontSize="3xl" width="full" mb={10}>
            {t("portfolio:instance_detail:title")} {instanceId}
          </Text>
          <div />
        </Flex>
        <Accordion w="full" allowMultiple bg="bg" borderRadius="lg">
          <AccordionItem border="none">
            <h2>
              <AccordionButton>
                <Box borderRadius="lg" bg="#005BD4" p={2} my={2} mr={4}>
                  <CalculatorIcon fill={"#ffffff"} width="31" height="31" />
                </Box>
                <Box as="span" flex="1" textAlign="left">
                  Setup
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <Divider mb={4} />
              <Calculator
                roundTo={item.portfolio_id_str[0] === "P" ? "6" : "5"}
                pair={item?.symbol}
                buyPercentage={x100(item?.profit_step)}
                capital={item?.init_capital}
                fee={"0.1"}
                priceUnit={item?.price_unit}
                currentPrice={parseFloat(item?.init_price)}
                currentFiat={parseFloat(item?.init_fiat)}
                mode="display"
                displayBanner={false}
              />
            </AccordionPanel>
          </AccordionItem>
        </Accordion>

        {isLoading && (
          <Flex justifyContent="center" alignItems="center" h="full">
            <Spinner />
          </Flex>
        )}

        <>
          <Tooltip anchorId="profit_1" style={{ opacity: 1 }}>
            <div>
              <img alt="1" src={img_1} />
            </div>
          </Tooltip>
          <Tooltip anchorId="profit_2" style={{ opacity: 1 }}>
            <div>
              <img alt="1" src={img_2} />
            </div>
          </Tooltip>

          {tableData && (
            <Flex
              mt={10}
              borderRadius="lg"
              overflow="hidden"
              overflowX="auto"
              w="full"
              css={{
                "::-webkit-scrollbar": {
                  width: "12px",
                },
                "::-webkit-scrollbar-track": {
                  "background-color": "#262338",
                  border: "1px solid #14142A",
                  "box-shadow": "inset 0 0 6px rgba(0, 0, 0, .3)",
                },

                "::-webkit-scrollbar-thumb": {
                  "background-color": "rgba(0, 0, 0, .3)",
                },
              }}
            >
              <Table>
                <Thead position="sticky" top={0} bg="#4E4B66">
                  <Tr>
                    <Th align="right" color="white">
                      Instance ID
                    </Th>
                    <Th align="right" color="white">
                      Buy
                    </Th>
                    <Th align="right" color="white">
                      Sell
                    </Th>
                    <Th align="right" color="white">
                      Transactions per month
                    </Th>
                    <Th align="right" color="white">
                      Profit crypto
                    </Th>

                    <Th id="profit_1" align="right" color="white">
                      Profit of portfolio [%]
                    </Th>

                    <Th id="profit_2" align="left" color="white">
                      Profit of portfolio2 [%] *
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {tableData
                    .filter((r) => r.InstanceId === instanceId)
                    .map((row) => (
                      <Tr key={row.InstanceId} height={10}>
                        <Th
                          align="right"
                          color="#EFF0F6"
                          fontWeight="normal"
                          border="1px solid #262338"
                        >
                          {row.InstanceId}
                        </Th>
                        <Th
                          align="right"
                          color="#EFF0F6"
                          fontWeight="normal"
                          border="1px solid #262338"
                        >
                          {row.Buy}
                        </Th>
                        <Th
                          align="right"
                          color="#EFF0F6"
                          fontWeight="normal"
                          border="1px solid #262338"
                        >
                          {row.Sell}
                        </Th>
                        <Th
                          align="right"
                          color={dark(row.TransactionAverage, 200)}
                          border="1px solid #262338"
                        >
                          {row.TransactionAverage}
                        </Th>
                        <Th
                          align="right"
                          color={dark(row.ProfitCrypto, 0)}
                          border="1px solid #262338"
                        >
                          {row.ProfitCrypto}
                        </Th>
                        <Th
                          align="right"
                          color={dark(row.PP1, 0)}
                          border="1px solid #262338"
                        >
                          {row.PP1}
                        </Th>
                        <Th
                          align="right"
                          color={dark(row.PP2, 0)}
                          border="1px solid #262338"
                        >
                          {row.PP2}
                        </Th>
                      </Tr>
                    ))}
                </Tbody>
              </Table>
            </Flex>
          )}
        </>

        <Flex
          alignItems="center"
          flexDirection="column"
          width="full"
          overflowX="hidden"
          mt={8}
        >
          {/* Tomasova instancia */}
          {Object.keys(graphsDataTranformed)
            .filter((key) => key === instanceId)
            .map((key) => (
              <Flex
                key={key}
                mb={10}
                flexDir="column"
                w="full"
                alignItems="center"
              >
                <ParentSize>
                  {({ width }) => (
                    <Flex justifyContent="center">
                      <Graph
                        width={width}
                        height={500}
                        fullData={graphsDataTranformed[key].data}
                      />
                    </Flex>
                  )}
                </ParentSize>
              </Flex>
            ))}
        </Flex>
      </Island>
    </>
  );
};

export default PortfolioDetail;
