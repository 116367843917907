import {
  Container,
  Flex,
  Text,
  Table,
  Tr,
  Th,
  Thead,
  Tbody,
  Box,
} from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import { getDataGraphs, getDataTables } from "../api/graphs";
import Graph from "../components/graph/Graph";
import ParentSize from "@visx/responsive/lib/components/ParentSize";

import Island from "../components/Island";
import { GraphData, GraphRecord, TableRecord } from "../utils/types";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip } from "react-tooltip";
import img_1 from "../images/1.png";
import img_2 from "../images/2.png";

import { ReactComponent as CheckIcon } from "../icons/check_circle.svg";

import { useTranslation } from "react-i18next";

const dark = (number: number, higherThan: number) =>
  number !== 0 ? "#A6F787" : "#FF75CB";

const Backtest = () => {
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 10);
  }, []);

  const { t } = useTranslation();

  const [type, setType] = useState<"GRAPHS" | "TABLE">("TABLE");

  const [graphsData, setGraphsData] = useState<GraphRecord[]>([]);
  const [tableData, setTableData] = useState<TableRecord[]>([]);

  // Initial data fetch.
  useEffect(() => {
    getDataGraphs(setGraphsData, "backtest");
    getDataTables(setTableData, "back_test");
  }, []);

  const graphsDataTranformed: GraphData = useMemo(() => {
    let graphsRecordsTransformed: GraphData = {};

    graphsData.map((graphRecord) => {
      graphsRecordsTransformed[graphRecord.InstanceId] = {
        data: [],
        flags: graphRecord.Flag,
      };
    });

    graphsData.map((graphRecord) => {
      graphsRecordsTransformed[graphRecord.InstanceId].data.push(graphRecord);
    });

    return graphsRecordsTransformed;
  }, [graphsData]);

  const filteredTableData = tableData;

  return (
    <>
      <Island>
        <Flex
          alignItems="center"
          justifyContent="space-between"
          flexDirection="row"
          w="full"
        >
          <Flex
            border="1px solid #50C8FC"
            mx={4}
            borderRadius="lg"
            cursor="pointer"
          >
            <Flex
              m={0}
              p={2}
              onClick={() => setType("TABLE")}
              bg={type === "TABLE" ? "#50C8FC" : "none"}
              color={type === "TABLE" ? "#14142A" : "white"}
              borderRadius="md"
              alignItems="center"
            >
              {type === "TABLE" && <CheckIcon fill="#14142A" />}
              <Text ml={2} fontWeight={300}>
                {t("backtest:statistics")}
              </Text>
            </Flex>
            <Flex
              m={0}
              p={2}
              onClick={() => setType("GRAPHS")}
              bg={type === "GRAPHS" ? "#50C8FC" : "none"}
              color={type === "GRAPHS" ? "#14142A" : "white"}
              borderRadius="md"
              alignItems="center"
            >
              {type === "GRAPHS" && <CheckIcon fill="#14142A" />}
              <Text ml={2}> {t("backtest:graphs")}</Text>
            </Flex>
          </Flex>
        </Flex>
      </Island>
      <Island>
        {type === "TABLE" ? (
          <>
            <Tooltip anchorId="profit_1" style={{ opacity: 1 }}>
              <div>
                <img alt="1" src={img_1} />
              </div>
            </Tooltip>
            <Tooltip anchorId="profit_2" style={{ opacity: 1 }}>
              <div>
                <img alt="1" src={img_2} />
              </div>
            </Tooltip>
            <Flex
              borderRadius="lg"
              overflow="hidden"
              overflowX="auto"
              w="full"
              css={{
                "::-webkit-scrollbar": {
                  width: "12px",
                },
                "::-webkit-scrollbar-track": {
                  "background-color": "#262338",
                  border: "1px solid #14142A",
                  "box-shadow": "inset 0 0 6px rgba(0, 0, 0, .3)",
                },

                "::-webkit-scrollbar-thumb": {
                  "background-color": "rgba(0, 0, 0, .3)",
                },
              }}
            >
              <Table>
                <Thead position="sticky" top={0} bg="#4E4B66">
                  <Tr>
                    <Th align="right" color="white">
                      Instance ID
                    </Th>
                    <Th align="right" color="white">
                      Buy
                    </Th>
                    <Th align="right" color="white">
                      Sell
                    </Th>
                    <Th align="right" color="white">
                      Transactions per month
                    </Th>
                    <Th align="right" color="white">
                      Profit crypto
                    </Th>

                    <Th id="profit_1" align="right" color="white">
                      Profit of portfolio [%]
                    </Th>

                    <Th id="profit_2" align="left" color="white">
                      Profit of portfolio2 [%] *
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {filteredTableData
                    .sort((a, b) => a.InstanceId.localeCompare(b.InstanceId))
                    .map((row) => (
                      <Tr key={row.InstanceId} height={10}>
                        <Th
                          align="right"
                          color="#EFF0F6"
                          fontWeight="normal"
                          border="1px solid #262338"
                        >
                          {row.InstanceId}
                        </Th>
                        <Th
                          align="right"
                          color="#EFF0F6"
                          fontWeight="normal"
                          border="1px solid #262338"
                        >
                          {row.Buy}
                        </Th>
                        <Th
                          align="right"
                          color="#EFF0F6"
                          fontWeight="normal"
                          border="1px solid #262338"
                        >
                          {row.Sell}
                        </Th>
                        <Th
                          align="right"
                          color={dark(row.TransactionAverage, 200)}
                          border="1px solid #262338"
                        >
                          {row.TransactionAverage}
                        </Th>
                        <Th
                          align="right"
                          color={dark(row.ProfitCrypto, 0)}
                          border="1px solid #262338"
                        >
                          {row.ProfitCrypto}
                        </Th>
                        <Th
                          align="right"
                          color={dark(row.PP1, 0)}
                          border="1px solid #262338"
                        >
                          {row.PP1}
                        </Th>
                        <Th
                          align="right"
                          color={dark(row.PP2, 0)}
                          border="1px solid #262338"
                        >
                          {row.PP2}
                        </Th>
                      </Tr>
                    ))}
                </Tbody>
              </Table>
            </Flex>
          </>
        ) : (
          <Flex
            alignItems="center"
            flexDirection="column"
            width="full"
            overflowX="hidden"
            paddingBottom={100}
          >
            {Object.keys(graphsDataTranformed).map(
              (key) =>
                graphsDataTranformed[key].flags
                  .map((flag) => flag.toLocaleLowerCase())
                  .sort((a, b) => a.localeCompare(b))
                  .includes("backtest") && (
                  <Flex
                    key={key}
                    my={10}
                    flexDir="column"
                    w="full"
                    alignItems="center"
                  >
                    <Text align="left" width="full" fontSize="2xl" mt={0}>
                      {key}
                    </Text>
                    <ParentSize>
                      {({ width }) => (
                        <Flex justifyContent="center">
                          <Graph
                            width={width}
                            height={500}
                            fullData={graphsDataTranformed[key].data}
                          />
                        </Flex>
                      )}
                    </ParentSize>
                  </Flex>
                )
            )}
          </Flex>
        )}
      </Island>
    </>
  );
};

export default Backtest;
