export const cs = {
  menu: {
    home_page: "Úvodní stránka",
    introduction: "Úvod",
    basic_information: "Základní informace",
    sample_portfolio: "Ukázkové portfolio",
    more_informations: "Více informací",
    they_mentioned_us: "Napsali o nás",
    pricing: "Ceník",
    my_applications: "Moje aplikace",
    reviews: "Recenze",
    roadmap: "Roadmapa",
    profile: "Profil",
    my_statistics: "Portfolio",
    dca: "DCA",
    api_keys: "API klíče",
    applications: "Aplikace",
    dcaxbotxhodl: "HODL vs HODLBOT",
    calculator: "Kalkulačka",
    statistics: "Statistiky",
    backtest: "Backtest",
    service: "Služba",
    general_terms: "Obecné podmínky",
    contacts: "Kontakty",
    logout: "Odhlásit se",
    login: "Přihlásit se",
    public_section: "Veřejná sekce",
  },
  introduction: {
    section_1: {
      title: "Akumulace kryptoměn pomocí matematiky",
      button: "Vytvoř si účet",
    },
    section_2: {
      box_1: "Uskutečněných transakcí",
      box_2: "Naakumulováno klientům",
      from: "od",
    },
    section_3: {
      title: "Základní informace",
      text_1: {
        part_1: "Navrhli jsme",
        part_2:
          "systém, který pomocí matematiky dokáže dlouhodobě množit Bitcoin",
        part_3: "za určitých předem definovaných podmínek. ",
        part_4: "využívá cenové volatility na vytvoření více jednotek,",
        part_5:
          " v našem případě Bitcoinu. Systém provádí nákupy a prodeje na spotovém trhu ze kterých vždy ",
        part_6: "naakumuluje zisk v BTC.",
      },
      title_2: "Jak získat zhodnocení na HODL portfoliu?",
      text_2: {
        part_1: "Některé coiny a tokeny lze",
        part_2: "“stakovat” a “lendovat”",
        part_3: "z čehož investor získá často velmi uspokojivý úrok.",
        part_4: "Bitcoin",
        part_5: "však nic podobného nenabízí",
        part_6:
          "a přitom je majoritně zastoupen skoro v každém HODL portfoliu. S řešením tohoto problému přichází právě",
        part_7: "Hodlbot",
        part_8: ", a to z dlouhodobého hlediska tohoto dilema!",
      },
      button: "Jak to funguje",
      title_3: "Proč vlastnit Bitcoin",
      text_3: {
        part_1: "1. Jeho",
        part_2: "nezávislost na státních orgánech a bankách.",
        part_3: "Bitcoin je decentralizovaná digitální měna, která umožňuje ",
        part_4: "přímé platby",
        part_5: "bez nutnosti prostředníka a bez nutnosti uvádět osobní údaje.",
        part_6: "2. Celkové",
        part_7: "množství Bitcoinu,",
        part_8: "který bude kdy existovat,",
        part_9: "je limitováno na 21 milionů.",
        part_10: "To znamená, že Bitcoin je už jen z pricipu",
        part_11: "stále lukrativnější pro investory a Hodlery",
        part_12: ", viz růst jeho ceny v dlouhodobém horizontu.",
      },
      title_4: "Benefity HODLBOTA",
      text_4: {
        part_1: "Množí Bitcoin",
        part_2: "pomocí matematiky",
        part_3: "Neovlivňují ho emoce",
        part_4: "funguje strojově",
        part_5: "Vytvoří zisk na portfoliu, které je nevyužité",
        part_6: "Využije každé cenové volatility",
        part_7: "Více než",
        part_8: "dvouroční historie",
        part_9: "Provoz",
        part_10: "24/7",
      },
    },
    section_4: {
      title: "Ukázkové portfolio",
      text_1: {
        part_1:
          "Spustili jsme ukázkové portfolio, které jsme se rozhodli sdílet a prezentovat na našem",
        part_2: "na našem Discordovém serveru.",
        part_3: "Spuštěno",
        part_4: "začátkem května 2022 při ceně 39 500 $ za BTC.",
        part_5: "Hodnota portfolia byla o velikosti",
        part_6: "1958 USDT a 0,02626 BTC (1038 USD)",
        part_7: "Obchoduje se po 1% a objem obchodu je 20 USD.",
        part_8: "Do 8.května 2023 Hodlbot udělal přes 3614 obchodů.",
        part_9: "Za toto období , na kterých vygeneroval zisk 0,01487 BTC.",
        part_10: "udělal 1790 tzv. dvoj-obchodů",
        part_11: "na kterých vygeneroval",
        part_12: "zisk 0,01487 BTC.",
        part_13:
          "Zisk v BTC je lepší než zisk v USD z dlouhodobějšího hlediska.",
        part_14: "Aktuální",
        part_15: "průměrná nákupní cena je již 25 200 $ ",
        part_16: ",náklad na nákup Bitcoinu je stále stejný a to",
        part_17:
          "1038 USD, za které bylo koupenou 0,02626 BTC při ceně 39 500 $.",
        part_18: "Pokud se cena dostane na počáteční hodnotu, tedy",
        part_19: "na 39 500$",
        part_20: ", bude portfolio v",
        part_21: "zisku minimálně 60%.",
        part_22: "Bez Hodlbota",
        part_23: "bych byl",
        part_24: "pouze na nule.",
        part_25: "Vše viz video.",
        part_26: "Ukázkové portfolio",
        part_27: "ZDE",
      },
    },
    section_5: {
      title: "Více informací",
      title_2: "MYŠLENKA HODL BOTA",
      text_1: {
        part_1: "Tvrdí hodleř",
        part_2:
          " jsou připraveni držet Bitcoin za jakýchkoliv okolností s dlouhým časovým rámcem.",
        part_3: "Rozumější hodleři",
        part_4:
          "mají předem stanovený plán částečného exitu, protože si uvědomují, že se jejich vklad například zdesetinásobil a rádi by se finančně „připojistili“, doplatili hypotéku, auto nebo si nějak jinak zlepšili či zabezpečili svůj život. Podle mě by měl každý mionimálně nad částí svého hodlu takto přemýšlet. Přesně takhle přemýšlím i já nad tím svým.",
        part_5: "S HODLem",
        part_6:
          "by se nemělo spekulovat a zkoušet časovat trh. Jde jen o to dodržovat předem stanovené úrovně, kde realizovat odprodeje a kde nákupy.",
        part_7:
          "Ale jak víme skutečnost je často úplně jiná. Většina investorů",
        part_8: "nemá ani předem stanovený plán.",
        part_9: "Dále často",
        part_10: "podléhají emocím",
        part_11:
          "a málo kdo se dokáže na trhu chovat strojově neboli bez emocí, zvládnout svou psychiku a",
        part_12: "dodržet pravidla.",
        part_13: "Hodlbot je postaven tak, abyste si",
        part_14: "definovali podmínky předem",
        part_15:
          "– maximální úrovně odprodeje, a naopak jak nízko chcete akumulovat.",
        part_16:
          "V tomto rozmezí pak Hodlbot využívá cenové volatility a realizuje",
        part_17: "„dvoj-obchody“ tzn. Nákup – prodej a to 24/7.",
        part_18: "Když trh klesá, tak nakupuje a když trh roste, tak prodává.",
        part_19:
          "Dokáže tak využít jakéhokoliv rychlého knotu a sebemenšího pohybu trhu.",
      },
      title_3: "JAK BOT FUNGUJE ?",
      text_2: {
        part_1: "Hodlbot funguje na systému",
        part_2: "obchodování s předem deklarovanými podmínkami,",
        part_3: "které se nastaví individuálně s pomocí kalkulátoru.",
        part_4: "Vaše kryptoměny neopustí váš účet",
        part_5: "a drtivá většína je stejně uložena v",
        part_6: "bezpečí na trezoru.",
        part_7: "Hodlbot se připojí pomocí",
        part_8: "API klíčů",
        part_9: "na váš účet na burze, kde je jen minimální část kapitálu.",
        part_10: "V API klíči povolíte zadávání obchodů do spotového trhu.",
        part_11: "Smazáním API klíče okamžitě Hodlbota vypnete",
        part_12:
          "a bot ztratí připojení, tzn. máté jako klient možnost vždy a okmažitě na cokoliv zareagovat. Hodlbot",
        part_13: "neobchoduje na páku ani neobchoduje žádné deriváty (CFD).",
        part_17: "Se vším vám pomůžeme.",
      },
    },
    section_6: {
      title: "Napsali o nás",
      text: {
        part_1:
          "HODLBOT - Appka pre každého HODLera Bitcoinu | HOSŤ : Tomáš Velek",
        part_2: "Diskuze a predstaveni HODL bota",
        part_3: "19.5.2023",
        part_4: "Zobrazit video",
        part_5: "Hodluj, ale chytře s Hodlbotem!",
        part_6:
          "Tohle je heslo, pod kterým si každý může představit něco jiného. Já si pod ním představuji odměny z hodlování Bitcoinu!",
        part_7: "19.5.2023",
        part_8: "Zobrazit článek",
        part_9: "Ukázkové portfolio - Snížení průměrné nákupní ceny o $ 15 000",
        part_10:
          "Jedná se o automatizovaný matematický systém, který je postavený tak aby využíval volatility Bitcoinu.",
        part_11: "10.5.2023",
        part_12: "Zobrazit video",
        part_13: "Zobrazit více (4)",
        part_14: "Jak získat něco navíc z HODL portfolia?",
        part_15:
          "Možná otázka, kterou si pokládáte, protože váš HODL je často velmi velká část vašeho portfolia v kryptoměnách. Některé coiny a tokeny se nechají...",
        part_16: "11.11.2022",
        part_17: "Zobrazit článek",
        part_18: "Ako si znížiť priemernú nákupku?",
        part_19:
          "Väčšina ľudí pri svojom hodle hľadá spôsob ako ho využiť viac ako na to aby ležal na trezore. Sú k dispozícii rôzne likvidity pooly alebo staking.",
        part_21: "9.2.2023",
        part_22: "Zobrazit článek",
        part_23: "Hodlbot ukázkové portfolio",
        part_24:
          "Po minulém článku ohledně Hodlbot jsem dostal pár opakujících se otázek, a to konkrétně: Kolik bot vydělá nebo s jak velkým kapitálem lze začít.",
        part_25: "2.6.2022",
        part_26: "Zobrazit článek",
        part_27: "Pasivní příjem z HODLování Bitcoinu",
        part_28:
          "Tvrdí hodleři jsou připraveni držet Bitcoin za jakýchkoliv okolností s vysokým časovým rámcem. Měkčí hodleři mají předem stanovený plán částečného...",
        part_29: "28.4.2022",
        part_30: "Zobrazit článek",
        part_31: "Zobrazit méně",
      },
    },
    section_7: {
      title: "Roadmapa",
      title_2: "Máme splněno",
      text: {
        part_1: "Q2 2021",
        part_2: "Navrhnutí a výzkum strategie",
        part_3: "Q2 2021",
        part_4: "Backtesting strategie",
        part_5: "Q3 2021",
        part_6: "Spuštění prototypu bota",
        part_7: "Q4 2021",
        part_8: "Uzavřené testovaní pro první zájemce",
        part_9: "Q1 2022",
        part_10: "Vizualizace dat do grafu",
        part_11: "Q1 2022",
        part_12: "Integrace uživatelských statistik",
        part_13: "Q2 2022",
        part_14: "Napojení burzy Phemex.com",
        part_15: "Q2 2022",
        part_16: "Integrace frakčního zůstaku na burze",
        part_17: "Q3 2022",
        part_18: "Emailové notifikace o stavech portfolia",
        part_19: "Q1 2023",
        part_20: "Nové backtesty do Q1 2023",
        part_21: "Q2 2023",
        part_22: "Nové webstránky",
      },
      title_3: "Co nás čeká?",
      text_2: {
        part_1: "Q2 2023",
        part_2: "Spuštení Afilliate prgramu",
        part_3: "Q2 2023",
        part_4: "Spuštění anglické varianty",
      },
      title_4: "Dlouhodobé cíle?",
      text_3: {
        part_1: "Q2 2022",
        part_2: "Spuštění DCA strategie",
        part_3: "Q2 2023",
        part_4: "Interace strategie na akcie a komodity",
        part_5: "Q2 2023",
        part_6: "Implementace platební brány",
        part_7: "Q2 2023",
        part_8: "Implementace rebalancující strategie",
        part_9: "Q3 2023",
        part_10: "Vytvoření vzdělávací sekce",
        part_11: "Q3 2023",
        part_12: "Implementovat další kryptoměnové burzy",
      },
    },
    section_8: {
      title: "Partneři",
    },
  },
  intro_information: `
    Společnost s ručeným omezením Algo Traders s.r.o., IČO: 19194536, se sídlem: Hoštičky 39, 33901 Klatovy, (dále jen „Společnost"), tímto poskytuje informace o způsobu a rozsahu zpracování osobních údajů svých klientů (dále jen „Klient"), včetně práv Klientů souvisejících se zpracováním osobních údajů (dále jen „Zásady"), a to v souladu se zákonem č. 110/2019 Sb., o zpracování osobních údajů a Nařízením (EU) 2016/679.

  `,
  data_processing_policies: `
    Správce osobních údajů a způsob zpracování údajů
    Společnost, jakožto správce osobních údajů, shromažďuje, uchovává a využívá osobní údaje ve spojení se svojí podnikatelskou činností a za účelem jejich řádné správy byly vytvořeny tyto Zásady.

    Zpracování a uchovávání osobních údajů probíhá v sídle Společnosti a je prováděno výlučně zaměstnanci Společnosti, nestanoví-li tyto Zásady jinak. Zpracování osobních údajů probíhá manuálně i automatizovaně v elektronických informačních systémech, a to v elektronické podobě, vždy při vysokém technickém, organizačním a personálním zabezpečení ve smyslu požadavků příslušné legislativy.

    Pakliže Klient využije služby Společnosti, kontaktní údaje Klienta může Společnost využít k tomu, aby Klientovi zasílala obchodní sdělení o službách, které poskytuje a které by Klienta mohly zajímat. Z odebírání obchodních sdělení se Klient může kdykoliv odhlásit zasláním zprávy na info@hodlbot.cz

    Účel zpracování osobních údajů
    Společnost působí při zpracování osobních údajů jako správce a určuje tak, za jakým účelem a jakými prostředky bude docházet ke zpracování osobních údajů. Osobní údaje jsou zpracovávány buď: (i) bez jakékoli další registrace k zpracování, na základě žádosti Klienta o informace, (ii) nebo na základě souhlasu Klienta, který byl Společnosti poskytnut.
    
    Doba uchovávání osobních údajů
    Společnost uchovává osobní údaje Klientů po dobu 10 let ode dne získání, pokud nedojde k dřívějšímu odvolání souhlasu k zpracování osobních údajů Klientem. 
    
    Klient má právo požadovat:
    - přístup ke svým osobním údajům,
    - opravu osobních údajů,
    - smazání osobních údajů,
    - omezení zpracování osobních údajů,
    - přenositelnost osobních údajů,
    - vznést námitku proti zpracování osobních údajů.
  `,
  hodl_x_hodlbot: {
    title: "HODL x HODLBOT",
    text_1: `HODL je slangový výraz pro strategii držení kryptoměn nebo jiných aktiv místo prodeje, i když cena klesá. V kryptoměnách je často Hodler, ten který drží a neprodává když cena klesá, ale i když cena roste. Tím pádem pokud cena vzroste od jeho nákupu a pak klesne tam kde nakoupil, nerealizuje žádný zisk. Užil si pravděpodobně jen stres.`,
    text_2:
      'Náš navržený systém Hodlbota je "alternativou" k předchozím strategiím, zároveň přináší mnoho výhod a možností.',
    text_3:
      "Systém Hodlbota díky volatilitě aktiva, v našem případě Bitcoinu, množí další jednotky Bitcoinu. Vše funguje na posloupné matematice, nikoliv tradingové strategii složené z indikátorů. Zároveň si dopredu nastavíte cenu, do kdy chcete Bitcoin nakupovat a do kdy jej chcete postupně odprodávat. Tím se zabvíte veškeré tíhy rozhodování a v daný moment kdy cena letí nahoru nebo dolů, jste v klidu.",
    title_2: "HODL X HODLBOT",
    text_4:
      "prvně se podívej na video ať chápeš jak systím funguje. Proč pro každého HODLera dává smysl využívat Hodlbota?",
    text_5:
      "Investor Pepa a Jirka jsou invesotři do Bitcoinu. Pepa je čistě Hodler, zatím co Jirka svoje portfolio alokuje na Hodlbota.",
    text_6: "Každý má úplně jiný výsledek po 2 a čtvrt roku.",
    text_7: "Dvě věci Pepu a Jirku spojují:",
    text_8: "Oba investovali ve stejný čas a koupili stejný počet Bitocinu",
    text_9: "Oba po celou dobu nic nedělali a měli nohy v teple.",
    text_10:
      "Pepa i Jirka koupili 1.1.2021 Bitcoin za $ 5 920 při ceně $ 28 500. Každý tedy má 0,2077 BTC. Pepa svůj Bitcoin pouze hodluje, zatím co Jirka svůj Bitcoin alokuje do správy Hodlbota s nastavením, že Jirkových 0,2077 BTC by bylo postupně odprodáno až do $ 179 000 za Bitcoin. K datu 31.3. 2023 tj. po dvou a čtvrt roce Bitcoin stál $ 28 500. Paradoxně uplně stejně jako na začátku. Pepa měl stále stvých stejných 0,2077 BTC se stejnou dolarovou hodnotu jakou investoval a stejnou tedy i průměrnou nákupní cenou. Po cestě si užil horskou dráhu a ve finále z toho nic nemá, jen psychickou újmu. Jirka mě svých 0,2077 BTC ze začátku plus jednotky naakumulovnaé Hodlbotem z volatility. Naakumuloval 0,12497 BTC. Jirká měl tedy dohromady 0,33269 BTC s dolarovou hodnotou $9 481, ale s průměrnou nákupní cenou $ 17 794.",
    text_11: "Pro zobrazeni detailnějšího popisu průběhu – rozkliknout",
    text_12:
      "Jirka dále počítal s případnou alokací $ 4 000 v případě poklesu ceny pod jeho nákupní cenu. (vysvěltlení je ve videu)",
    text_13:
      "Po půl roce od spuštění tj. 1.7.2021 Bitcoin stál $ 33 400. Pepa měl stále stejný zůstatek 0,2077 BTC, což mělo hodnotu $ 6 937. Jirkovi za tu dobu Hodlbot naakumuloval 0,04924 BTC. Po součtu s jeho zbývajícím měl dohormady 0,22079 BTC. Jirkův Bitcoin tedy měl hodnotu $ 7 374, s tím, že již měl exitovaný nějaký Bitocin, protože cena byla výše než na počátku, konkrétně měl z odprodejů volných $ 1 050. S tím, jak Jirka akumuloval další Bitcoin zdarma, klesla jeho průměrná nákupní cena na $ 23 040. Po roce od spuštění tj. 1.1.2022 Bitcoin stál $ 47 340. Pepových 0,2077 Bitcoinu mělo již hodnotu $ 9 832. Jirka měl 0,17426 BTC, což mělo hodnotu $ 8 249. K tomu měl ještě již exitovaný volný kapitál $ 3 570. Jirkova průměrná nákupní cena byla $ 21 760.",
    text_14:
      "Po roce a půl od spuštění tj. 1.7. 2022 Bitcoin stál $ 19 333. Pepových 0,2077 Bitcoinu mělo hodnotu $ 4 015. Jirka měl 0,41707 BTC, měl více Bitcoinu, protože se cena dostala pod jeho cenu při začátku, jeho čistá hodnota Bitcoinu je 0,2077 (počátek) + jeho zisk který byl 0,29935 BTC. Pouze jeho nakoupený a vydělaný Bitcoin měl hodnotu $ 5 787. Poté tedy jeho průměrná nákupní cena byla $ 19 776.",
    text_15:
      "Po dvou letech od spuštení tj. 1.1. 2023 Bitcoin stál $ 16 488. Pepových 0,2077 BTC mělo hodnotu $ 3 424 a většina investorů i když ví, že chce držet, tak jim není příjemné být na pozici ve velké ztrátě zejména po tom co byly ve vlekém zisku. Jirka měl 0,50342, měl více Bitcoinu, protože cena byla stále pod jeho cenou při začátku. Počáteční zůstatek Bitocinu a jeho zisky v Bitcoinu byly dohromady 0,32255 BTC. Průměrná nákupní cena byla tedy $ 18 353.",
    text_16:
      "K datu 31.3. 2023 tj. po dvou a čtvrt roce Bitcoin stál $ 28 500. Paradoxně uplně stejně jako na začátku.",
    text_17:
      "Pepa měl stále stvých stejných 0,2077 BTC se stejnou dolarovou hodnotou jakou investoval a stejnou tedy i průměrnou nákupní cenou. Po cestě si užil horskou dráhu a ve finále nic z toho nemá, jen psychickou újmu.",
    text_18:
      "Jirka měl svých 0,2077 BTC ze začátku plus jednotky naakumulovnaé Hodlbotem z volatility. Naakumuloval 0,12497 BTC. Jirká měl tedy dohromady 0,33269 BTC s dolarovou hodnotou $9 481, ale s průměrnou nákupní cenou $ 17 794.",
  },
  contacts: {
    title: "Kontaktní formulář",
    text_1: "Jméno a příjmení",
    text_2: "E-mailová adresa",
    text_3: "Dotaz",
    text_4: "Zaslat dotaz",
  },
  affiliate: {
    title: "Affiliate program",
    text_1: "Pojď šířit dál dobrou myšlenku!",
    text_2:
      "Pokud se tento projekt líbí budeme rádi, když jej budete sdílet mezi vaše přítele o kterých si myslíte, že by je Hodlbota mohl zaujmout, ale zejména by jim mohl být prospěšný.",
    text_3: {
      part_1: "Pro tyhle důvody spouštíme tento",
      part_2: "affiliate program",
      part_3: `Abyste
      měli nárok na odměnu rámci programu, je potřeba aby se vámi
      přivedený klient zaregistroval pod vaším referenčním kódem, který si
      zde na platformě vygenerujete. Dále je potřeba aby si přivedený
      klient spustil našeho Hodlbota. Náš tým mu pomůže, jak s nastavením
      vhodného portfolia tak i se samotným spuštěním, případně vysvětlení
      a doplnění všech nejasností.`,
    },
    text_4:
      "Pokud klient dokončí a následně zaplatí první fakturu, tj za první placený měsíc. (pozn: může nastat, že v rámci nějaké akce může, být určitý časový úsek využívání Hodlbota zdarma, takže za tento úsek vám nenáleži odměna)",
    text_5: {
      part_1: "Odměna je ve výši",
      part_2: "50% z 1. platby",
      part_3:
        "(tj. prvního placeného měsíce). Odměnu vám buď vyplatíme nebo ji odečteme od vaši provize, kterou byste nám zaplatili v rámci vašeho spuštěného Hodlbota.",
    },
    text_6: {
      part_1:
        "Toto všechno platí pokud není žádná jiná individuální dohoda mezi vámi a námi (Algo Traders s.r.o.) Pokud máte zájem o individuální podmínky v rámci affiliate, tak se na nás můžete obrátit prostřednictvím",
      part_2: "kontaktního formulářu.",
    },
    checkbox: {
      part_1: "Souhlasím s",
      part_2: "podmínkami affiliate programu",
    },
    message: {
      success: "Promo kód byl vygenerován",
      error: "Nepodařilo se vygenerovat promo kód, prosím kontaktujte nás",
      copy: "Odkaz byl zkopírován do schránky",
    },
    button: "Vygenerovat promo kód",
    table: {
      text_1: "Promo kód",
      text_2: "Počet reg",
      text_3: "Počet aktivních",
      text_4: "Vyplaceno (czk)",
      text_5: "Nevyplaceno (czk)",
      text_6: "Link",
      text_7: "Total",
    },
  },
  api_keys: {
    title: "API klíče",
    text_1: "Api key",
    text_2: "Secret key",
    text_3:
      "Opětovným vytvořením se přepíší stávající api klíče pro danou burzu.",
    button: "Updatnúť / vytvoriť api klíče",
    text_4: "Zatím nemáte žádné api klíče.",
    button_1: "Přidat api klíč",
    title_1: "Zvolte burzu, na které chcete obchodovat.",
    binance:
      "Binance je jednou z předních kryptoměnových burz, založenou v roce 2017. Nabízí obchodování s množstvím kryptoměn a vyniká svou tokenovou měnou, Binance Coin (BNB), která se používá k snižování poplatků a dalším funkcím na platformě. Je vhodná pro velká portfolia právě díky vysoké likviditě a nejnižším poplatkům, které mohou být pouze 0,065%.",
    phemex:
      "Phemex je rychle rostoucí kryptoměnová burza, která byla založena v roce 2019. Nabízí obchodování s různými kryptoměnami a je známa svou uživatelsky přívětivou platformou a konkurenceschopnými poplatky. Je ideální burzou pro menší portfolio, má srovnatelné poplatky jako ostatní burzy. Výhodou je, že obchoduje každý instrument na více desetinných míst než ostatní burzy.",
    text_5: {
      part_1: "Vím, jak postupovat, neboť jsem viděl",
      part_2: "video návod",
      binance: "pro burzu Binance.",
      phemex: "pro burzu Phemex.",
      bybit: "pro burzu Bybit.",
      coinmate: "pro burzu Coinmate.",
    },
    title_2: {
      part_1: "Zadejte api key a secret key pre burzu",
      binance: "BINANCE",
      phemex: "PHEMEX",
      bybit: "BYBIT",
      coinmate: "COINMATE",
    },
    text_6: "Správne som nastavil povolenia na read & trading",
    button_2: "Uložit",
    button_3: "Zpět",
    title_3: "O api klíčích",
    text_7:
      "Pokud ještě nemáte vytvořený účet na burze vašeho výběru, pokračujte kliknutím na logo burzy.",
    text_8: "Pokud vám něco není jasné, níže máte k dispozici video návod.",
    notif_success: "Api klíče byly úspěšně uloženy.",
    notif_error: "Nepodařilo se uložit api klíče.",
    text_9: {
      part_1: "Chci obchodovat na ",
      binance: "Binance",
      phemex: "Phemex",
      bybit: "Bybit",
      coinmate: "Coinmate",
    },
    text_10: "Propojit svůj Binance účet",
    text_11: "Propojit svůj Phemex účet",
    text_12: "Vytvořit nový Binance účet",
    text_13: "Vytvořit nový Phemex účet",
    text_14: "Propojit svůj Bybit účet",
    text_15: "Vytvořit nový Bybit účet",
    text_16: "Propojit svůj Coinmate účet",
    text_17: "Vytvořit nový Coinmate účet",
    bybit:
      "Bybit, založená v roce 2018, je známá svým rozsáhlým portfoliem kryptoměnových služeb, včetně spotového obchodování. Tato platforma nabízí obchodování s širokou škálou kryptoměn a je oblíbená pro svou intuitivní uživatelskou platformu a efektivní zákaznickou podporu. Poplatky za spotové obchodování na Bybitu jsou 0,1%. Je vhodná pre velké portfolio.",
    coinmate:
      "Coinmate, založená v roce 2014, je uznávaná pro své služby v oblasti spotového obchodování s kryptoměnami. Tato burza se zaměřuje na evropské trhy a nabízí obchodování s několika hlavními kryptoměnami. Je známá pro své uživatelsky příjemné rozhraní a rychlou podporu klientů. Poplatky za spotové obchodování na Coinmate jsou  0,6%. Táto burza je vhodná pre všetkých, ktorí prefruju europsku, dokonca českú spoločnosť.",
    text_18: "Client id",
    not_connected: "Stav: Nepřipojeno",
  },
  portfolio: {
    title: "Portfolio",
    no_statistics: "Zatím žádne statistiky nemáš.",
    show_more: "Zobrazit více",
    show_less: "Zobrazit méně",
    card: {
      instance_id: "ID Instance",
      expiration: "Expirace",
      active: "Aktivní",
      show_detail: "Zobrazit detail",
    },
    instance_detail: {
      title: "Detail instance",
      no_rights: "Nemáte práva na zobrazení detailu instance.",
    },
  },
  calculator: {
    traded_pair: "Obchodovaný pár",
    information_1: "např.: BTC/USDT",
    jump: "% Skok",
    information_2:
      "Zadej, jaký má být procentuální rozptyl mezi jednotlivými obchodními úrovněmi. Doporučeno 1% - 2%, záleží ale od velikosti portfolia. Čím menší procento, tím větší počet obchodů, ale zase menší zisk z jednoho páru nákup - prodej.",
    exchange: "Burza",
    information_3: "Na kolik desetinných míst burza zaokrouhluje.",
    trade_volume: "Objem obchodu",
    information_4: "hodnota 20 při páru BTCUSDT = 20 USDT",
    fee_in: "Poplatek v %",
    information_5:
      "Jak velký máte poplatek za obchod na spot trhu u vaší burzy.",
    capital: "Kapitál",
    information_6:
      "Velikost celkového kapitálu tj. součet hodnot ve FIAT a součet dolarové hodnoty kryptoměny ve vámi zadané ceně.",
    guide_to_hodlbot_part_1: "Návod na",
    guide_to_hodlbot_part_2: "Hodlbota",
    guide_to_hodlbot_part_3: "a",
    guide_to_hodlbot_part_4: "kalkulačku",
    guide_to_hodlbot_part_5: "zde",
    guide_to_hodlbot_part_6: "ku kalkulačke",
    index: "INDEX",
    percentages: "PROCENTA",
    price: "CENA (KURZ)",
    information_7:
      "Zadej cenu kryptoměny od které chceš Hodlbota spočítat. Nejčastěji se tedy jedná o aktuální cenu.",
    fiat: "FIAT",
    information_8:
      "Zadejte hodnotu ve fiatu, kolik chceš alokovat na Hodlbota. FAIT ovlivňuje hodnotu min buy.",
    crypt_balance: "ZŮSTATEK KRYPTO",
    crypto_profit: "ZISK KRYPTO",
    fiat_profit: "ZISK FIAT",
    init_price: "CENA INIT",
  },
  backtest: {
    statistics: "statistiky",
    graphs: "grafy",
  },
  statistics: {
    statistics: "statistiky",
    graphs: "grafy",
    active: "aktivní",
    all: "všechno",
    pending: "čekající",
    ended: "ukončeny",
  },
  profile: {
    notif_success: "Fakturační údaje byly úspěšně uloženy",
    notif_error: "Neznámá chyba",
    title: "Fakturační údaje",
    text_1: "Prosím zadejte vaše fakturační údaje",
    text_2: "Osobní údaje",
    text_3: "Jméno",
    text_4: "Příjmení",
    text_5: "E-mailová adresa",
    text_6: "Telefonní číslo (nepovinné)",
    text_7: "Fakturační adresa",
    text_8: "Ulice a Č.P.",
    text_9: "Město",
    text_10: "PSČ",
    text_11: "Stát",
    button_1: "Uložit",
    button_2: "Resetovat heslo",
    text_12: "Chci dostávat emailové notifikace",
    text_13: 'Marketingové sdělení, např. "Novinky, akce, slevy"',
  },
  login: {
    title: "Přihlášení",
    text_1: "Prosím zadejte vaše přihlašovací údaje",
    text_2: "E-mailová adresa",
    text_3: "Heslo",
    button_1: "Přihlásit se",
    text_4: {
      part_1: "Nemáte účet? ",
      part_2: "Zaregistrujte se",
    },
    text_5: "Zapomenuté heslo?",
    notif_error:
      "Nepodařilo se přihlásit, zkontrolujte prosím vaše přihlašovací údaje.",
  },
  register: {
    required: "Povinné pole",
    wrong_email: "Špatný formát emailu",
    not_match: "Hesla se neshodují",
    must_agree: "Musíte souhlasit",
    title: "Registrace",
    text_1: "Prosím vyplňte následující údaje",
    text_2: "Jméno",
    text_3: "Zadejte vaše jméno",
    text_4: "Příjmení",
    text_5: "Zadejte vaše příjmení",
    text_6: "E-mailová adresa",
    text_7: "Zadejte vaši e-mailovou adresu",
    text_8: "Heslo",
    text_9: "Zadejte heslo",
    text_10: "Potvrzení hesla",
    text_11: "Zadejte heslo znovu",
    text_12: "Promo kód",
    text_13: {
      part_1: "Souhlasím s ",
      part_2: "obchodními podmínkami",
    },
    button_1: "Registrovat",
    errors: {
      1: "Účet s rovnakým emailom už existuje",
      2: "Neznámá chyba",
    },
    success: "Úspěšne zaregistrován",
  },
  pricing: {
    text_1: {
      part_1: "Hodlbot je služba,",
      part_2: " která generuje zisk klientovi v kryptoměně,",
      part_3: " nejčastěji je zvolený Bitcoin. Služba je zpoplatněná",
      part_4: " 15% procenty ze zisku.",
      part_5:
        " Vždy na konci domluveného období (standardně měsíční nebo kvartální) dojde k výpočtu zisku v kryptoměně,",
      part_6:
        " ze zisku v kryptoměně se spočte 15%, a to je provize za služby Hodlbota.",
    },

    text_2:
      "Většina klientů platí provizi ve FIATU (CZK, EUR, USD). To znamená, že se provize v kryptoměně převede do požadované měny ve směnném kurzu v té dané chvíli.",
    text_3: "Příklad od výpočtu provize za 2. kvartál.",
    text_4: {
      part_1: "k 1.7. je",
      part_2: "cena kryptoměny 100 000 CZK",
      part_3: "1.7. - 1.4. = 2.kvartál",
      part_4: "0,012 - 0,06 =",
      part_5: "0,06",
      part_6: "zisk v kryptoměně",
      part_7: "* 15% =",
      part_8: "0,009",
      part_9: "provize v kryptoměně",
      part_10: "0,009",
      part_11: "* 100 000",
      part_12: "900 CZK",
      part_13: "provize v CZK",
      part_14: "hrubý zisk v CZK",
      part_15: "čistý zisk v CZK",
    },
  },
  dca_create: {
    title: "Vytvořit DCA instanci",
    title_update: "Upravit DCA instanci",
    subtitle:
      "Dollar Cost Averaging (DCA), známé také jako průměrování dolarových nákladů, je investiční strategie, jejímž cílem je snížit vliv volatility při nákupu aktiv. Tato strategie spočívá v nákupu jednotlivých aktiv nebo portfolia aktiv za stejnou částku po přepočtu na fiat peníze v pravidelných časových intervalech.",
    name_title: "Pojmenujte si instanci",
    name_placeholder: "Např.: Lepší budoucnost",
    name_info: `Tento název vám pomůže udržet kurz k vašim finančním cílům a připomínat vám, proč pravidelně investujete – ať už je to 'Důchodový Fond' nebo 'Vzdělání Pro Děti'.`,
    name_api_key: "API klíč",
    api_key_title: "Propojení s Burzou",
    api_key_info:
      "Zadejte svůj API klíč pro připojení k vaší investiční platformě. Tím umožníte nastavit a automatizovat vaši DCA strategii, aby odpovídala vašim investičním cílům a preferencím.",
    api_key_placeholder: "Vyberte si API klíč",
    name_pair: "Obchodovatelný pár",
    pair_info: `Zadejte obchodovatelný pár, který chcete pravidelně kupovat. Například, pro investici do Bitcoinu pomocí amerického dolaru zadejte 'BTC/USDT'`,
    pair_placeholder: "Např.: BTC-USDT",
    pair_disabled_placeholder: "Nejdříve vyberte api klíč",
    name_amount: "Za kolik chcete nakoupit?",
    amount_info: "Zadejte částku, kterou chcete pravidelně investovat.",
    amount_text_1: "Nakoupit za",
    amount_text_2: "Aktuálně na burze",
    amount_text_3: "K dispozici na {{amount}} nákupů",
    name_interval: "Jak často chcete provádět nákupy?",
    interval_text: "Každých",
    interval_info: `Zvolte pravidelnost nákupů pro vaši DCA strategii, od několika minut po týdny, a udržujte tak disciplinovaný přístup k investování.`,
    name_first_buy: "Počáteční nákup",
    first_buy_info: ` Nastavte datum a čas, kdy má začít vaše DCA strategie. Strategie začne automaticky provádět nákupy podle vašeho harmonogramu od zadaného data a času a bude pokračovat, dokud nevyčerpáte alokovaný kapitál.`,
    first_buy_text: "Kapitál vydrží do",
    name_special: "Dynamické nákupy odchýlka pod priemernu cenu",
    special_text_1: "Pokud je aktuální cena nižší od nákupní ceny o",
    special_info:
      "Využitím této funkce dynamicky upravujete objem nákupu na základě aktuální tržní ceny. Pokud je tržní cena nižší než aktuální průměrná nákupní cena, systém automaticky zvýší množství nákupu, čímž dochází k průměrování nákladů směrem dolů. To vám může pomoci snížit celkovou průměrnou cenu vašich investic, ale může také rychleji vyčerpat váš alokovaný kapitál.",
    special_text_2: ", tak navýšit nákupnú částku o",
    name_price_border: "Omezení cenou",
    special_button: "+ Přidat další pravidlo",
    price_border_text_1: "Pokud je cena {{unit}} nad",
    price_border_text_2: ", tak upravit nákupnú částku o",
    price_border_text_3: "násobek.",
    price_border_info:
      "Omezení cenou vám umožňuje nastavit hodnotu, při jejíž překročení se automaticky sníží objem vašeho nákupu. Například, nastavíte-li hranici na 50 000 USDT a tržní cena Bitcoinu stoupne nad tuto hodnotu, systém upraví nákupní objem dolů. To pomáhá předcházet nákupu za vysoké ceny a zvyšuje efektivitu vaší strategie tím, že udržuje nákupy v příznivějších cenových bodech.",
    price_border_special_info:
      "Když bude cena vyšší než {{limit}} {{unit}}, tak se nakoupí za {{ratio}} {{unit}}.",
    submit: "Vytvořit instanci",
    minutes: "Minuty",
    hours: "Hodiny",
    days: "Dny",
    weeks: "Týdny",
    months: "Měsíce",
    no_api_keys:
      "Pro vytvoření DCA instance potřebujete mít uložené API klíče.",
    no_api_keys_link: "+ Vytvořit API klíč",
    update: "Upravit instanci",
    soon: "Brzy",
    amount: "Částka",
    ratio: "Násobek",
    capital_restriction: "Omezení kapitálom",
    capital_restriction_text: "Na nákupy alokovat",
    capital_restriction_text_2: "po prekročení čiastky stratégiu zastavit.",
    capital_restriction_info:
      "Zadejte maximální sumu, kterou chcete investovat do vaší DCA strategie. Tato částka představuje horní limit celkového kapitálu.",
    dynamic_strategy: "Dynamická stratégia NUPL",
    more_info: "Více informací o pripravované stratégii",
    here: "zde",
    dynamic_strategy_under_name: "Dynamické nákupy odchýlka nad priemernu cenu",
    optional: "Nepovinné",
  },
  dca: {
    title: "DCA",
    active: "Aktivní",
    stopped: "Zastaveno",
    insufficient_balance: "Nedostatek kapitálu",
    limit_exceeded: "Překročen limit",
    minutes: "Minut",
    hours: "Hodin",
    days: "Dnů",
    weeks: "Týdnů",
    months: "Měsíců",
    what_is_dca: "Co je DCA?",
    what_is_dca_info:
      "Průměrování dolarových nákladů (DCA) je investiční strategie, při níž investor rozděluje celkovou investovanou částku na pravidelné nákupy cílového aktiva ve snaze snížit dopad volatility na celkový nákup. K nákupům dochází bez ohledu na cenu aktiva a v pravidelných intervalech.",
    no_instances: "Nemáte žádné instance DCA",
    no_instances_info:
      "Vytvořte si vaši první DCA instanci a začněte s ní investovat.",
  },
  dca_detail: {
    title: "Informace o instnaci",
    api_key: "API klíč",
    pair: "Obchodovaný pár",
    amount: "Nakoupit za",
    frequency: "Frekvence",
    last_buy: "Poslední nákup",
    next_buy: "Nasledující nákup",
    special_buys: "Výjimečné nákupy",
    price_border: "Omezení cenou",
    modal_title: "Přejete si změnit stav?",
    modal_text: "Přejete si změnit stav z ",
    to: " na ",
    cancel: "Zrušit",
    confirm: "Potvrdit",
    special:
      "Pokud je aktuální cena nižší o {{percentage}}%, tak navýšit priceunit o {{value}} {{sign}}.",
    price_limit:
      "Pokud je cena nad {{limit}}$, tak omezit priceunit o {{ratio}} násobek",
    transactions_history: "Historie transakcí",
    number_of_transactions: "Počet transakcí",
    bought: "Nakoupeno",
    show: "Zobrazit transakce",
    amount_trans: "Částka",
    buy: "Nákup",
    sell: "Prodej",
  },
  online_meeting: {
    title: "Online schůzka",
  },
};
